import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'semantic-ui-css/semantic.min.css'
import { GlobalProvider } from './context/GlobalState';
import { isMobile,isIE } from 'react-device-detect';

const IEStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100vh",
  color: "black",
  padding: "1rem",
  textAlign: "center"
}

if (isIE || isMobile) {
  ReactDOM.render(<div style={IEStyle}>
    {isMobile ? <h1>Application is not supported on mobile yet, Coming Soon</h1> : <>
      <h1>Hi there. You’re using an outdated browser</h1>
      <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, Opera, or Edge.</p>
    </>}
  </div>,document.getElementById('root'));
} else {
  ReactDOM.render(<GlobalProvider>
    <App />
  </GlobalProvider>,
    document.getElementById('root'));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
