import React,{ useEffect,useMemo,useState } from 'react';
import { Link,useLocation } from 'react-router-dom';
import { Dropdown,Image,Menu,Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { useStore } from '../context/GlobalState';
import { Images } from '../images';
import { Container,Button } from 'semantic-ui-react';
import { AppBorderPrimaryButton } from 'elements';
import { ContractUtility,Protocols } from 'utility';
import { setProtocol } from 'store/actions';
import { loadBlockchain } from 'store/asyncActions';
import { createMedia } from '@artsy/fresnel';
import {
  Icon,
  Sidebar
} from 'semantic-ui-react'
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const { MediaContextProvider,Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

const Header = styled.header`
  background : #092236;
  padding: 1em 2em;
`

const SideMenuClose = styled(Button)`
position: absolute;
bottom: 16px;
right: 16px;`

const MobileHeader = styled.header`
  background : #092236;
  padding: 1em 0em;
`

const MenuItem = styled(Menu.Item)`
  &.active {
    a {
      color : ${({ theme }) => theme.colors.active} !important;
    }
    a:before {
      content: '';
      background: #29F49A;
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      left: calc(50% - 5px);
      top: calc(-1em + -4px);
    }
  }
  a {
    color : #FFFFFF !important;
    font-size: 18px;
    letter-spacing: 0.374px;
  }
`

const ProtocolMenuItem = styled(Menu.Item)`
  padding:  0 !important;
  width: 150px;
`

const ProductDropdown = styled(Dropdown)`
  background-color: #01A0FB !important;
  border-color: #01A0FB !important;
  color: white !important;
  border-radius: 50px !important;
  width : 100%;
    .icon {
      right: 15px;
      position: absolute !important;
    }
  }
`

const AddressButton = styled(AppBorderPrimaryButton)`
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
`

const Banner = styled(Message)`
  margin: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
`

export const HeaderBar = () => {


  const location = useLocation();
  const [sidebarOpened,setSidebarOpened] = useState(false);
  const [{ web3Loadded,accounts,protocol,network,networkShouldBe,web3LoadingErrorMessage },dispatch] = useStore();

  const options = useMemo(() => [{
    value: Protocols.ethereum.name,
    text: "Ethereum",
    image: { avatar: true,src: Images.networks.ethereum }
  },{
    value: Protocols.bsc.name,
    text: "BSC",
    image: { avatar: true,src: Images.networks.bsc },
  },{
    value: Protocols.polygon.name,
    text: "Polygon",
    image: { avatar: true,src: Images.networks.polygon },
  }],[]);

  let web3Modal;

  useEffect(() => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: ContractUtility.getInfuraKey()
        }
      },
    };

    web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: providerOptions
    });

    connectWallet();

  },[]);

  const protocolChanged = (value) => {
    const rightNetwork = ContractUtility.getRightNetwork(value);
    dispatch(setProtocol({
      networkShouldBe: rightNetwork,
      protocol: value
    }));
  }

  const handleSidebarHide = () => {
    setSidebarOpened(false);
  }

  const handleToggle = () => {
    setSidebarOpened(true);
  }

  const connectWallet = async () => {
    const provider = await web3Modal.connect();
    loadBlockchain(provider,dispatch);
  }

  return (
    <>
      <MediaContextProvider>
        <Media greaterThan='mobile'>
          <Header>
            <Container>
              <Menu secondary>
                <MenuItem position="left">
                  <Link to="/">
                    <Image src={Images.logo} size="mini" />
                  </Link>
                </MenuItem>

                <MenuItem
                  name='rent'
                  active={location?.pathname === "/" || location?.pathname.indexOf("/rent") > -1}
                >
                  <Link to="/">Rent </Link>
                </MenuItem>

                <MenuItem
                  name='lend'
                  active={location?.pathname.indexOf("/lend") > -1}
                >
                  <Link to="/lend">Lend</Link>
                </MenuItem>

                <MenuItem
                  name='dashboard'
                  active={location?.pathname === "/dashboard"}
                >
                  <Link to="/dashboard">Dashboard </Link>
                </MenuItem>

                <Menu.Menu position='right'>
                  <ProtocolMenuItem>
                    <ProductDropdown
                      item
                      selectOnBlur={false}
                      options={options}
                      value={protocol}
                      onChange={(_,{ value }) => protocolChanged(value)}
                      placeholder="Select Protocol"
                    />
                  </ProtocolMenuItem>
                  <MenuItem>
                    {!web3Loadded || (accounts || []).length === 0 ? <AppBorderPrimaryButton onClick={connectWallet}>
                      Connect Wallet
                    </AppBorderPrimaryButton> : <AddressButton title={accounts[0]}>
                      {accounts[0]}
                    </AddressButton>}
                  </MenuItem>
                </Menu.Menu>
              </Menu>
            </Container>
          </Header>
        </Media>

        <Media at="mobile">
          <MobileHeader>
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
              </Menu>
            </Container>
          </MobileHeader>
        </Media>
      </MediaContextProvider>

      <Sidebar as={Menu} animation='overlay' icon='labeled' inverted vertical visible={sidebarOpened} width='wide'>
        <Menu.Item
          as={Link}
          to="/"
          onClick={handleSidebarHide}
          active={location?.pathname === "/" || location?.pathname.indexOf("/rent") > -1}
        >
          Rent
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/lend"
          onClick={handleSidebarHide}
          active={location?.pathname.indexOf("/lend") > -1}
        >
          Lend
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/dashboard"
          onClick={handleSidebarHide}
          active={location?.pathname === "/dashboard"}
        >
          Dashboard
        </Menu.Item>

        <Menu.Item>
          <Dropdown
            text='Select Protocol'
            selectOnBlur={false}
            options={options}
            value={protocol}
            onChange={(_,{ value }) => protocolChanged(value)}
          >
          </Dropdown>
        </Menu.Item>

        <Menu.Item>
          {!web3Loadded || (accounts || []).length === 0 ? <AppBorderPrimaryButton onClick={connectWallet}>
            Connect Wallet
          </AppBorderPrimaryButton> : <AddressButton title={accounts[0]}>
            {accounts[0]}
          </AddressButton>}
        </Menu.Item>

        <SideMenuClose icon onClick={handleSidebarHide}>
          <Icon name='arrow left' />
        </SideMenuClose>
      </Sidebar>

      {web3Loadded && network && (networkShouldBe || '').toLowerCase() !== (network || '').toLowerCase() && <Banner color="orange">
        You're viewing data from the {networkShouldBe} network, but your wallet is connected to {network}, please switch your network to {networkShouldBe}
      </Banner>}
      {!web3Loadded && web3LoadingErrorMessage && <Banner color="orange">
        {web3LoadingErrorMessage}
      </Banner>}
    </>
  );
};
