import { AppListPlaceholder,NFTItem,PaymentTypeImage } from "components";
import { AppPrimaryOutlineButton } from "elements";
import { Card } from "semantic-ui-react";
import styled from "styled-components";
import { PaymentType } from "utility";

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const FlexRow = styled.div`
    display : flex;
    align-items : center;
    justify-content : space-between;
`

const NFTList = ({ data,stopLending,claimCollateral }) => (
    <Card.Group itemsPerRow={4} stackable doubling>
        {data.map((item) => (
            <NFTItem
                key={item._id}
                item={item}
                metadata={item.nft_metadata}
                extraInfo={<div className="my-2">
                    <p>Price: <PaymentTypeImage type={item.payment_type || PaymentType.WETH} className="mx-1" /> {item.nft}</p>
                    <p>Daily Price: <PaymentTypeImage type={item.payment_type || PaymentType.WETH} className="mx-1" /> {item.daily}</p>
                </div>}
                actionButton={<FlexRow>
                    <AppPrimaryOutlineButton size="mini" onClick={() => stopLending(item)}>
                        Stop Lending
                    </AppPrimaryOutlineButton>
                    <AppPrimaryOutlineButton size="mini" disabled={!item.claimCollateral} onClick={() => claimCollateral(item)}>
                        Claim Colletral
                    </AppPrimaryOutlineButton>
                </FlexRow>}
            />
        ))}
    </Card.Group>
);

export const MyLendList = ({ loading,data,stopLending,claimCollateral }) => (
    <>
        {loading ? (
            <AppListPlaceholder rows={1} itemPerRow={4} />
        ) : (
            <NFTList
                data={data || []}
                stopLending={stopLending}
                claimCollateral={claimCollateral}
            />
        )}
        {!loading && data.length === 0 &&
            <div className="my-4">
                <SmallText>You are not lending any NFTs</SmallText>
            </div>
        }
    </>
)