import { useEffect,useState } from "react"
import { ErrorConstant,LendService } from "../utility";
import { unpackDailyPrice } from "store/bytesHandler";


export const MyLendListHook = (address,contractAddress) => {

    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await LendService.my(address,contractAddress);
                const temp = (result.data || []).map(item => {
                    let endPoint = (item.block_timestamp || 0) + (item.duration_seconds || 0);
                    let startPoint = ~~(Date.now() / 1000);
                    return {
                        ...item,
                        nft: unpackDailyPrice(item.nft_price),
                        daily: unpackDailyPrice(item.daily_rent_price),
                        claimCollateral: startPoint >= endPoint
                    }
                });
                setData(temp);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        if (address && contractAddress) {
            fetchData();
        }
    },[address,contractAddress]);

    return { data,loading,error,setData };
}
