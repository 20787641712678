export const theme = {
    colors: {
        primary: "#01A0FB",
        danger: '#F6392D',
        black: "#1B1A1C",
        headerBg: "#092236",
        active: "#29F49A",
        border: "#E6E8EC",
        white: "#FFFFFF",
        background: "#E5E5E5",
        black1: "#292343",
        grey: '#555555',
        plus: '#37D7A7',
        minus: '#F13C5D'
    },
    button: {
        outline: "rgba(2, 163, 249, 0.1)",
        border: "#02A3F9"
    },
    card: {
        title: "#23262F",
        subTitle: "#777E90"
    },
    closeButton: {
        background: "#D0DEEB",
        color: "white"
    }
}