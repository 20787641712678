
import { AppDangerButton } from 'elements'
import React from 'react'
import { Modal,Image } from 'semantic-ui-react'
import styled from "styled-components";
import { Images } from "../images";

const AppModal = styled(Modal)`
    text-align: center !important;
    border-radius: 20px !important;
    
    .textWrapper {
        font-size: 18px;
        letter-spacing: 0.374px;    
        h1 {
            font-size: 24px;
            margin: 0px !important;
            letter-spacing: 0.374px;
        }
    }
    .content {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        .title {
            letter-spacing: 13.374px !important;
            text-transform: uppercase !important;
            padding-top: 40px !important;
            color: black !important;            
        }
        .imageWrapper {
            position: relative;
            .success {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-72%);
            }
        }
    }
    .actions {
        text-align: center !important;
        background: #F1F5F8 !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border-top: none !important;
    }
`

export const SuccessBox = ({ open,closeModal,title,actionBar }) => (
    <AppModal
        open={open}
        onClose={closeModal}
        size="mini"
    >
        <Modal.Content className="content">
            <div className="imageWrapper">
                <Image className="success" src={Images.successTickRounded} />
            </div>
            <p className="title">
                Success
            </p>
            <div className="textWrapper">
                {title}
            </div>
        </Modal.Content>
        <Modal.Actions>
            {actionBar}
        </Modal.Actions>
    </AppModal>
)

export const ErrorBox = ({ open,closeModal,title,}) => (
    <AppModal
        open={open}
        onClose={closeModal}
        size="mini"
    >
        <Modal.Content className="content">
            <div className="imageWrapper">
                <Image className="success" src={Images.error} />
            </div>
            <p className="title">
                Error
            </p>
            <p>
                {title}
            </p>
        </Modal.Content>
        <Modal.Actions className="textWrapper">
            <AppDangerButton onClick={closeModal}>Okay</AppDangerButton>
        </Modal.Actions>
    </AppModal>
)