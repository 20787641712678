import React,{ createContext,useReducer,useEffect,useContext } from 'react';
import AppReducer from '../store/reducer';
import { APIPath,Protocols,ContractUtility } from '../utility';
import { addEthereumAccounts,setNetwork,web3LoadingError } from 'store/actions';

// Initial state
const initialState = {
    transactions: [],
    web3: null,
    accounts: [],
    contract: null,
    apiTrigger: false,
    apiUrl: APIPath.server,
    web3LoadingErrorMessage: "",
    network: "",
    web3Loadded: false,
    protocol: Protocols.ethereum.name,
    networkShouldBe: ContractUtility.getRightNetwork(Protocols.ethereum.name)
}

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {

    const [state,dispatch] = useReducer(AppReducer,initialState);

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged',(accounts) => {
                dispatch(addEthereumAccounts(accounts));
                if ((accounts || []).length === 0) {
                    dispatch(web3LoadingError("Disconnected or No Account found"));
                }
            });

            window.ethereum.on('chainChanged',(netId) => {
                console.log(netId);
                let network = ContractUtility.getNetwork(netId);
                dispatch(setNetwork({ network }));
            });
        }

    },[]);

    return (<GlobalContext.Provider value={[state,dispatch]}>
        {children}
    </GlobalContext.Provider>);
}

export const useStore = () => useContext(GlobalContext);