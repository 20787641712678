import { Images } from "images";
import styled from "styled-components";
import { PaymentType } from "utility";

const PaymentImage = styled.img`
    width : 15px;
    height : 15px;
`

const getImage = (type) => {
    switch (type) {
        case PaymentType.WETH:
            return Images.payment.weth

        case PaymentType.USDC:
            return Images.payment.usdc

        default:
            break;
    }
}

export const PaymentTypeImage = ({ type,className }) => (
    <PaymentImage src={getImage(type)} className={className || ""} />
)