import { CommonConstant,Protocols } from "utility";

const RENTAL_ADDRESS = {
    [Protocols.ethereum.name]: process.env.REACT_APP_ETHEREUM_CONTRACT_ADDRESS,
    [Protocols.bsc.name]: process.env.REACT_APP_BSC_CONTRACT_ADDRESS,
    [Protocols.polygon.name]: process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS,
}

const PAYMENT_ADDRESS = {
    [Protocols.ethereum.name]: process.env.REACT_APP_ETHEREUM_PAYMENT_CONTRACT_ADDRESS,
    [Protocols.bsc.name]: process.env.REACT_APP_BSC_PAYMENT_CONTRACT_ADDRESS,
    [Protocols.polygon.name]: process.env.REACT_APP_POLYGON_PAYMENT_CONTRACT_ADDRESS,
}


export class ContractUtility {

    static getInfuraKey = () => process.env.REACT_APP_INFURA_KEY;

    static getContractAddress = (protocol) => {

        switch (protocol) {
            case Protocols.ethereum.name:
            case Protocols.bsc.name:
            case Protocols.polygon.name:
                return RENTAL_ADDRESS[protocol]

            default:
                return null;
        }
    }

    static getPaymentAddress = (protocol) => {

        switch (protocol) {
            case Protocols.ethereum.name:
            case Protocols.bsc.name:
            case Protocols.polygon.name:
                return PAYMENT_ADDRESS[protocol]

            default:
                return null;
        }
    }

    static getContractAddress = (protocol) => {

        switch (protocol) {
            case Protocols.ethereum.name:
            case Protocols.bsc.name:
            case Protocols.polygon.name:
                return RENTAL_ADDRESS[protocol]

            default:
                return null;
        }
    }

    static getNetwork = (netId) => {
        console.log(netId);
        switch (netId) {
            case "1":
            case "0x1":
                return "Main";
            case "2":
            case "0x2":
                return "Morden";
            case "3":
            case "0x3":
                return "Ropsten";
            case "4":
            case "0x4":
                return "Rinkeby";
            case "42":
            case "0x42":
                return "Kovan";
            case "13881":
            case "0x13881":
            case "80001":
            case "0x80001":
                return "Mumbai";
            case "89":
            case "0x89":
                return "polygon";
            case "38":
            case "0x38":
                return "bsc";
            case "61":
            case "0x61":
                return "bsc testnet";
            default:
                return "Unknown";
        }
    }

    static getRightNetwork = (protocol) => {
        if (CommonConstant.mode === "PRODUCTION") {
            switch (protocol) {
                case Protocols.ethereum.name:
                    return "Main"
                case Protocols.polygon.name:
                    return "polygon"
                case Protocols.bsc.name:
                    return "bsc"
                default:
                    return false;
            }
        } else {
            switch (protocol) {
                case Protocols.ethereum.name:
                    return "Rinkeby"
                case Protocols.polygon.name:
                    return "mumbai"
                case Protocols.bsc.name:
                    return "bsc testnet"
                default:
                    return false;
            }
        }
    }

    static getTokenAddress = (protocol,address) => {
        switch (protocol) {
            case Protocols.ethereum.name:
            case Protocols.bsc.name:
            case Protocols.polygon.name:
                const mode = CommonConstant.mode === "PRODUCTION" ? "mainnet" : "testnet";
                const url = Protocols[protocol].assetUrl[mode]
                return `${url}/${address}`

            default:
                return null;
        }
    }

    static getTransaction = (protocol,address) => {
        switch (protocol) {
            case Protocols.ethereum.name:
            case Protocols.bsc.name:
            case Protocols.polygon.name:
                const mode = CommonConstant.mode === "PRODUCTION" ? "mainnet" : "testnet";
                const url = Protocols[protocol].txUrl[mode]
                return `${url}/${address}`

            default:
                return null;
        }
    }

}

