import { Images } from "../images";
import { ImageWithFallback } from 'components';
import { Image,Card } from 'semantic-ui-react';
import styled from "styled-components";


export const VeraCard = styled(Card)`
  &.ui.card {
    padding: 11px;
    box-shadow: none;
    border-radius: 20px;
    >.content {
      padding: 0px !important;
      .image {
        border-radius: 20px !important;
      }
    }
    .background {
      background-size: cover;
      height: 305px;
      width: 100%;
      border-radius: 20px !important;
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #23262F;
      padding-top: 10px;
      margin-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subTitle {
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    >:last-child{
      border-radius : inherit !important;
    }

    
  }
}
`;

export const VeraCardShadow = styled(VeraCard)`
  box-shadow: 0px 24px 48px 0 rgb(0 0 0 / 16%) !important;
`

const TokenLink = styled.a`
    position : absolute;
    right : 10px;
    z-index :1000;
`

export const NFTItem = ({ item,imgClick,metadata,actionButton,extraInfo }) => (
  <VeraCard>
    <div className="content">
      <div>
        <ImageWithFallback
          style={{ height: "300px", width: "100%", objectFit: "cover",margin: "0 auto" }}
          fallback={Images.card1}
          src={metadata?.image || Images.card1}
          size="medium"
          onClick={() => imgClick && imgClick()}
        />

        {metadata?.permalink && <TokenLink target="_blank" href={metadata?.permalink}>
          <Image src={Images.opensea} size="mini" />
        </TokenLink>}

        <div>
          <p className="title">
            {metadata?.name || item.name || <>&nbsp;</>}
          </p>
          <p className="subTitle">
            {metadata?.description}
            {metadata.name === 'NASA' && 'Helloooo ad adsasda d asd asda da d asd ad ad adad ad ad ad adhasd ad ad adh ad'}
          </p>
        </div>
      </div>
    </div>

    <div>
      {extraInfo}
      <div className="my-2">
        {actionButton}
      </div>
    </div>

  </VeraCard>
)

export const OpenSeaNFTItem = ({ item,imgClick,actionButton,extraInfo }) => (
  <VeraCard>
    <div className="content">
      <div>
        <ImageWithFallback
          style={{ height: "300px", width: "100%", objectFit: "cover",margin: "0 auto" }}
          fallback={Images.card1}
          src={item?.image_preview_url || Images.card1}
          size="medium"
          onClick={() => imgClick && imgClick()}
        />
        <TokenLink target="_blank" href={item.permalink}>
          <Image src={Images.opensea} size="mini" />
        </TokenLink>
        <div>
          <p className="title">
            {item.name || <>&nbsp;</>}
          </p>
          <p className="subTitle">
            {item.description}
          </p>
        </div>
      </div>
    </div>

    <div>
      {extraInfo}
      <div className="my-2">
        {actionButton}
      </div>
    </div>
  </VeraCard>
)