import { useState } from 'react';
import { Image } from 'semantic-ui-react';


export const ImageWithFallback = ({ fallback,src,...props }) => {
    const [imgSrc,setImgSrc] = useState(src)
    const onError = () => setImgSrc(fallback)

    return <Image src={imgSrc ? imgSrc : fallback} onError={onError} {...props} />
}
