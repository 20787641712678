import React from "react";
import { Container,Grid } from "semantic-ui-react";
import { UserNFTListHook } from "../hooks";
import { AppListPlaceholder,OpenSeaNFTItem } from "../components";
import { AppPrimaryButton,ContainerFull,CornerLogos,PageTitle,NoRecordsFound } from "elements";
import { useStore } from "context/GlobalState";
import { useNavigate } from "react-router";

// const FilterBar = styled.div`
//   margin: 1rem 0;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

const NFTList = ({ data,onClick }) => (
  <Grid>
    {data.map((item) => (
      <Grid.Column mobile={16} tablet={8} computer={4} key={item.id}>
        <OpenSeaNFTItem
          item={item}
          actionButton={<AppPrimaryButton
            fluid
            onClick={() => onClick(item)}>
            Lend
          </AppPrimaryButton>}
        />
      </Grid.Column>
    ))}
  </Grid>
);

export const LendScreen = () => {
  let navigate = useNavigate();

  const [{ accounts,web3Loadded,protocol }] = useStore();

  const { data,loading,hasMore,pageChanged } = UserNFTListHook(protocol,accounts ? accounts[0] : '');

  const onClick = (item) => {
    navigate(`/lend-details/${item.token_id}/${item.asset_contract.address}`)
  }

  return (
    <ContainerFull>
      <CornerLogos />
      <Container className="wrapper">
        <PageTitle>Lend</PageTitle>
        {web3Loadded ?
          <div>
            <NFTList
              data={data || []}
              onClick={onClick}
            />
            {!loading && data.length === 0 &&
              <div className="my-4">
                <NoRecordsFound>
                  No NFT for lend
                </NoRecordsFound>
              </div>
            }
            {loading && <AppListPlaceholder rows={2} itemPerRow={4} />}
            {hasMore && <div className="row my-4">
              <div className="col-12 text-center">
                <AppPrimaryButton
                  onClick={() => pageChanged()}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Load more data'}
                </AppPrimaryButton>
              </div>
            </div>}
          </div> : <div className="my-4">
            <NoRecordsFound>
              Connect to Wallet
            </NoRecordsFound>
          </div>}
      </Container>
    </ContainerFull>
  );
};
