import React,{ useState,useEffect,useMemo } from "react";
import styled from "styled-components";
import {
  Checkbox,
  Form,
  Grid,
  Icon,
  List,
  Radio,
  Segment,
  Container,
  Card,
} from "semantic-ui-react";
import { RentDetailsHook } from "../hooks";
import {
  LoaderBar,
  SuccessBox,
  ErrorBox,
  ImageWithFallback,
  PaymentTypeImage,
} from "../components";
import { useNavigate,useParams } from "react-router";
import {
  AppPrimaryCancelButton,
  AppPrimaryButton,
  ContainerFull,
  CornerLogos,
  Box,
  FormTextFormField
} from "elements";
import { ERC20_ABI } from "contract/ERC20_ABI";
import { getPaymentMethodAddress,rentAsync } from "store/asyncActions";
import { CommonUtility,ContractUtility,PaymentType,RentService } from "utility";
import { useStore } from "context/GlobalState";
import { Images } from "images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Payment_ABI } from "contract/Payment_ABI";
import { RENTAL_ABI } from "contract/RENTAL";

const VeraDetailsCard = styled(Card)`
  &.ui.card {
    padding: 11px;
    border-radius: 20px;
    box-shadow: none !important;
    >.image {
      border-radius: 20px !important;
    }        
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.card.title};
      padding-top: 10px;
      text-align: center;
    }
    
  }
}
`;

const Details = styled(Segment)`
  &.ui.segment {
    background: #fcfcfd !important;
    border: 1px solid #e6e8ec !important;
    box-sizing: border-box !important;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 20px !important;
    .item {
      border-top: 0px;
    }
    .right {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70px;
    }
  }
`;

const PaymentDetails = styled(Segment)`
  &.ui.segment {
    width: 320px !important;
    background: #fcfcfd !important;
    border: 1px solid #e6e8ec !important;
    box-sizing: border-box !important;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 20px !important;
    .item .description {
      padding-bottom: 12px;
      font-weight: bold;
      font-size: 14px;
      line-height: 132%;
      color: #23262f;
    }
    .item .amount {
      padding-top: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      letter-spacing: -0.4px;
      color: ${({ theme }) => theme.colors.black1};
    }
    .item .label {
      padding-top: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: -0.4px;
      color: #292343;

      .moreDetails {
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #292343;
        opacity: 0.4;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`;

const Description = styled.p`
  margin-top : 1rem;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
`

const RadioContainer = styled.div`
  display: flex;
  align-items: center;

  .disabled{
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const RentForm = styled.div`
  .rentFormWrapper {
    .paymentOptions {
      padding-top: 25px;
      .title {
        padding-bottom: 19px;
      }
      .ui.divided.list {
        .item {
          padding: 14px 0px;
        }
      }
    }
    .acceptOptions {
      padding: 14px 0px;
    }

    .paymentButton {
      padding: 14px 0px;
    }

    .moreDetails {
      padding: 14px 0px;
    }
  }
`;

const Address = styled(List.Content)`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;
`

const ErrorViewMore = styled.span`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;
`

const DetailsSchema = yup.object().shape({
  duration: yup.number().required("Duration is required").positive("Duration should be positive"),
});

export const RentDetailsScreen = () => {

  const navigate = useNavigate();
  const params = useParams();
  const [{ web3,accounts,protocol }] = useStore();
  const { data,loading } = RentDetailsHook(params?.id);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DetailsSchema),
  });

  const duration = watch("duration");

  const requiredToken = useMemo(() => CommonUtility.roundNumber(data?.nft + ((duration || 0) * data?.daily) || 0,5),[data,duration]);

  const [formData,setFormData] = useState({
    payment: "full",
    terms: false,
    ownRisk: false,
  });

  const [processing,setProcessing] = useState("");
  const [error,setError] = useState("");
  const [success,setSuccess] = useState(false);
  const [transactionHash,setTransactionHash] = useState("");


  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    if (processing) {
      window.addEventListener("beforeunload",unloadCallback);
      return () => window.removeEventListener("beforeunload",unloadCallback);
    }
  },[processing]);

  const inputChanged = (key,value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const approve = async (formData) => {
    try {
      if (formData.duration > data?.max_duration) {
        setError(`Duration should be less than ${data?.max_duration}`);
        return;
      }

      setSuccess(false);
      setError("");

      setProcessing("Approving");
      const paymentAddress = ContractUtility.getPaymentAddress(protocol);
      const paymentContract = new web3.eth.Contract(Payment_ABI,paymentAddress);
      const paymentMethod = await getPaymentMethodAddress(paymentContract,data.payment_type || PaymentType.WETH);

      const contractErc20 = new web3.eth.Contract(ERC20_ABI,paymentMethod);

      // Get balance
      const balance = await contractErc20.methods.balanceOf(accounts[0]).call();
      const weiValue = web3.utils.toWei(requiredToken.toString(),'ether');

      if (+balance < +weiValue) {
        setError(`Insufficient Balance to make this transactions.`);
        return;
      }

      let amount = 300 * 10 ** 18;
      amount = amount.toString();
      const rentalAddress = ContractUtility.getContractAddress(protocol);

      await contractErc20.methods
        .approve(rentalAddress,amount)
        .send({ from: accounts[0] });
      await contractErc20.methods.allowance(accounts[0],rentalAddress).call();


      const contract = new web3.eth.Contract(RENTAL_ABI,ContractUtility.getContractAddress(protocol));

      let token_address = data.token_address;
      let token_id = data.token_id;
      let lend_id = data.lend_id;
      let receipt = await rentAsync(contract,accounts,token_address,token_id,lend_id,formData.duration);

      const transaction_hash = receipt?.transactionHash;
      setTransactionHash(transaction_hash);
      if (receipt && receipt.status) {
        let blockNumber = receipt.blockNumber;
        let timestamp = await web3.eth.getBlock(blockNumber);
        let block_timestamp = timestamp.timestamp
        let user_duration = formData.duration

        const reqData = {
          user_address: accounts[0],
          block_timestamp,
          user_duration,
          transaction_hash
        };
        await RentService.update(data._id,reqData);
        setSuccess(true);
      } else {
        setError("Error while processing");
      }
    } catch (error) {
      console.log(error);
      const transaction_hash = error?.transactionHash;
      setTransactionHash(transaction_hash);
      setError("Error while processing");
    } finally {
      setProcessing("");
    }
  };

  const goToDashboard = () => {
    setSuccess(false)
    navigate("/dashboard");
  }

  const successCancel = () => {
    setSuccess(false)
    setTransactionHash("");
    navigate("/");
  }

  const tokenAddress = (address) => {
    const url = ContractUtility.getTokenAddress(protocol,address);
    window.open(url,"_blank");
  }

  const openTransaction = (tx) => {
    const url = ContractUtility.getTransaction(protocol,tx);
    window.open(url,"_blank");
  }

  return (
    <ContainerFull>
      <CornerLogos />
      <Container className="wrapper">
        <Box>
          {(loading || processing) && (
            <LoaderBar size="big" content={processing ? <>
              <p className="m-0">{processing}</p>
              <p>Don't close or refresh the page please. Transaction can take a while.</p>
            </> : "Loading"} />
          )}
          {!loading && data && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <VeraDetailsCard>
                    <ImageWithFallback
                      fallback={Images.card1}
                      src={data?.nft_metadata?.image || Images.card1}
                      size="medium"
                    />
                    <Title className="title">{data?.nft_metadata?.name}</Title>
                    <Details>
                      <List divided verticalAlign="middle">
                        <List.Item>
                          <Address floated='right' onClick={() => tokenAddress(data.token_address)}>
                            {data.token_address}
                          </Address>
                          <List.Content>Contact address</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated='right'>
                            <p>{data?.token_id}</p>
                          </List.Content>
                          <List.Content>Token ID</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated='right'>
                            <p>{data?.nft_metadata?.token_standard}</p>
                          </List.Content>
                          <List.Content>Token Standard</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated='right'>
                            <PaymentTypeImage type={data.payment_type || PaymentType.WETH} className="mx-1" />
                            {data?.daily}
                          </List.Content>
                          <List.Content>Daily Price</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated='right'>
                            <PaymentTypeImage type={data.payment_type || PaymentType.WETH} className="mx-1" />
                            {data?.nft}
                          </List.Content>
                          <List.Content>Colleteral</List.Content>
                        </List.Item>
                      </List>
                    </Details>
                  </VeraDetailsCard>
                </Grid.Column>

                <Grid.Column width={10}>
                  <RentForm>
                    <Form className="rentFormWrapper" noValidate onSubmit={handleSubmit(approve)}>
                      <div>
                        <FormTextFormField
                          label="How many days do you want to rent?"
                          placeholder={`Maximum ${data?.max_duration} days`}
                          defaultValue={""}
                          type="number"
                          name="duration"
                          control={control}
                          required
                          errors={errors.duration}
                        />
                      </div>

                      <div className="paymentOptions">
                        <Title className="title">Payment options</Title>
                        <List divided>
                          <List.Item>
                            <RadioContainer>
                              <Radio
                                name="payment"
                                value="full"
                                checked={formData.payment === "full"}
                                onChange={(_,data) =>
                                  inputChanged("payment",data.value)
                                }
                              />
                              <div className="mx-3 ">
                                <Title>Full payment</Title>
                                <small>You will be billed only once</small>
                              </div>
                            </RadioContainer>
                          </List.Item>
                          <List.Item>
                            <RadioContainer>
                              <Radio
                                disabled
                                name="payment"
                                value="monthly"
                                checked={formData.payment === "monthly"}
                                onChange={(_,data) =>
                                  inputChanged("payment",data.value)
                                }
                              />
                              <div className="mx-3 disabled">
                                <Title>Month</Title>
                                <small>You will be billed every month</small>
                              </div>
                            </RadioContainer>
                          </List.Item>
                          <List.Item>
                            <RadioContainer>
                              <Radio
                                disabled
                                name="payment"
                                value="up-front"
                                checked={formData.payment === "up-front"}
                                onChange={(_,data) =>
                                  inputChanged("payment",data.value)
                                }
                              />
                              <div className="mx-3 disabled">
                                <Title>Pay less up front</Title>
                                <small>
                                  The rest ($343) will be charged on Mar 15. No
                                  extra fees. Learn more
                                </small>
                              </div>
                            </RadioContainer>
                          </List.Item>
                        </List>
                      </div>
                      <div className="acceptOptions">
                        <div>
                          <Checkbox
                            label="I agree with the Terms & Services "
                            checked={formData.terms}
                            onChange={(_,data) =>
                              inputChanged("terms",data.checked)
                            }
                          />
                        </div>
                        <div>
                          <Checkbox
                            label="Contract in beta and not audited, I am using at my own risk"
                            checked={formData.ownRisk}
                            onChange={(_,data) =>
                              inputChanged("ownRisk",data.checked)
                            }
                          />
                        </div>
                      </div>

                      <div className="paymentButton">
                        <AppPrimaryButton
                          disabled={!formData.terms || !formData.ownRisk}
                        >
                          Approve payment tokens
                        </AppPrimaryButton>
                      </div>

                      <PaymentDetails>
                        <List divided verticalAlign="middle">
                          <List.Item>
                            <List.Content className="description">
                              {data?.nft_metadata?.name}
                            </List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content floated="right" className="amount">
                              <p>{requiredToken}</p>
                            </List.Content>
                            <List.Content className="label">
                              <span>Total (<PaymentTypeImage type={data.payment_type || PaymentType.WETH} className="mx-1" />)</span>
                              <br />
                              <span className="moreDetails">See details</span>
                            </List.Content>

                          </List.Item>
                        </List>
                      </PaymentDetails>
                    </Form>
                  </RentForm>
                </Grid.Column>
              </Grid.Row>
              {data?.nft_metadata?.description && <Grid.Row>
                <Grid.Column>
                  <b>Description:</b>
                  <Description>{data?.nft_metadata?.description}</Description>
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          )}
        </Box>

        <SuccessBox
          open={success}
          closeModal={() => successCancel(false)}
          title={<div>Congratulation <br /> <h1>your rent is successful!</h1></div>}
          actionBar={
            <>
              <AppPrimaryCancelButton onClick={() => successCancel()}>
                Close
              </AppPrimaryCancelButton>
              <AppPrimaryButton onClick={() => goToDashboard()}>Go To Dashboard</AppPrimaryButton>
            </>
          }
        />

        <ErrorBox
          open={!!error}
          closeModal={() => setError("")}
          title={<>{error} {transactionHash && <ErrorViewMore
            className="ml-1"
            onClick={() => openTransaction(transactionHash)}
          >
            View Logs <Icon name="external alternate" />
          </ErrorViewMore>}
          </>}
        />

      </Container>
    </ContainerFull >
  );
};
