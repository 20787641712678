import styled from "styled-components";
import { Card,Image } from 'semantic-ui-react';
import { Images } from "../images";


export const Header = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
`;

export const ContainerFull = styled.div`
  min-height: calc(100vh - 80px);
  background-color: ${({ theme }) => theme.colors.background} !important;
  .topRight {
    top: 0;
    right: 0;
    position: absolute;
  }
  .bottomLeft {
    left: 0;
    bottom: 0;
    position: fixed;
  }
`;


export const VeraPlaceholderCard = styled(Card)`
&.ui.card {
    padding: 11px;
    box-shadow: 0px 24px 48px 0 rgb(0 0 0 / 16%);
    border-radius: 20px;
    >.placeholder {
      border-radius: 20px !important;
      height: 319px;
    }
    .content {
      border-top: none !important;
    }
  }
}
`;

export const VeraDashboardPlaceholderCard = styled(Card)`
&.ui.card {
    height: 12rem;
    padding: 11px;
    box-shadow: 0px 24px 48px 0 rgb(0 0 0 / 16%);
    border-radius: 20px;
    >.placeholder {
      border-radius: 20px !important;
      height: 319px;
    }
    .content {
      border-top: none !important;
    }
  }
}
`;

export const DashboardCard = styled(Card)`
  &.ui.card {
    margin: unset !important;
    margin-right: 20px !important;
    height: 12rem;
    padding: 11px;
    width: 100%;
    border-radius: 20px;
    .content {
      border-top: none !important;
      padding: 8px;
    }
    .title {
      padding-top: 4px !important;
      font-size: 18px !important;
      line-height: 18px !important;
      color: #727272 !important;

      .info {
        float: right;
      }
    }
    .footer {
      font-weight: bold !important;
      font-size: 48px !important;
      line-height: 48px !important;
      color: #555555 !important;
      .trending {
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        padding-left: 4px;
        &.plus {
          color: #37D7A7 !important;
          :after {
            content: ' ';
            position: absolute;
            bottom: 25px;
            border-color: transparent transparent ${({ theme }) => theme.colors.plus} transparent;
            border-style: solid;
            border-width: 0px 7px 7px 7px;
            height: 0px;
            width: 0px;
            margin-left: 2px;
          }
        }
        &.minus {
          color: #F13C5D !important;
          :after {
            content: ' ';
            position: absolute;
            bottom: 20px;
            border-color: ${({ theme }) => theme.colors.minus} transparent transparent transparent;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            height: 0px;
            width: 0px;
            margin-left: 4px;
          }
        }
      }
    }
  }
`;

export const CornerLogos = () => (
  <>
    <Image className="topRight" src={Images.logoTopRight} />
    <Image className="bottomLeft" src={Images.logoBottomLeft} />
  </>
);

export const Box = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 40px 114px;
`;

export const PageTitle = styled.h1`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px;
`

export const PageSubTitle = styled.p`
  margin-top: 28px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey};
`

export const NoRecordsFound = styled.p`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey};
`