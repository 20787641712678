import React from "react";
import { Container } from "semantic-ui-react";
import { ContainerFull,PageTitle,PageSubTitle,DashboardCard,CornerLogos } from "elements";
import { Grid,Image,Card } from 'semantic-ui-react';
import { Images } from '../images';

export const TransactionsScreen = () => {

    return (
        <ContainerFull>
            <CornerLogos />
            <Container className="wrapper">
                <PageTitle>Dashboard</PageTitle>

                <PageSubTitle>Lending Data</PageSubTitle>

                <Grid columns='three'>
                    <Grid.Row>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Weekly Revenue
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        $2458
                                        <span className="trending plus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Listed Assets
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        158
                                        <span className="trending minus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Rented Assets
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        21
                                        <span className="trending minus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <PageSubTitle>Renting Data</PageSubTitle>
                <Grid columns='three'>
                    <Grid.Row>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Weekly Payments
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        $1538
                                        <span className="trending plus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Rented Assets
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        538
                                        <span className="trending minus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                        <Grid.Column>
                            <DashboardCard>
                                <Card.Content>
                                    <Card.Header className="title">
                                        Total Collateral Staked
                                        <Image className="info" src={Images.info} />
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content extra>
                                    <Card.Header className="footer">
                                        $1538
                                        <span className="trending minus">2.32%</span>
                                    </Card.Header>
                                </Card.Content>
                            </DashboardCard>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <PageTitle>Transaction History</PageTitle>
            </Container>
        </ContainerFull>
    );
};
