

import { BaseService } from './base';
import { APIPath } from '../constant';
import { CommonUtility } from 'utility';

class Rent {

    get = (params) => {
        let url = APIPath.rent;
        const reqParams = CommonUtility.objectToParams(params);
        return BaseService.get(`${url}?${reqParams}`);
    }

    getById = (id) => {
        return BaseService.get(`${APIPath.rent}/${id}`);
    }

    update = (id,data) => {
        return BaseService.put(`${APIPath.rent}/${id}`,data)
    }

    return = (id) => {
        return BaseService.put(`${APIPath.returnNFT}/${id}`,null)
    }

    my = (user,contractAddress) => {
        return BaseService.get(`${APIPath.myrent}/${user}/${contractAddress}`);
    }

}

const RentService = new Rent();
Object.freeze(RentService);
export { RentService };
