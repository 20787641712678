

import { BaseService } from './base';
import { APIPath } from '../constant';

class Lend {

    add = (data) => {
        return BaseService.post(APIPath.lendNFT,data)
    }

    cancel = (id) => {
        return BaseService.put(`${APIPath.cancelLending}/${id}`,null)
    }

    my = (user,contractAddress) => {
        return BaseService.get(`${APIPath.mylend}/${user}/${contractAddress}`);
    }

    claim = (id) => {
        return BaseService.put(`${APIPath.claimNFT}/${id}`,null)
    }

}

const LendService = new Lend();
Object.freeze(LendService);
export { LendService };
