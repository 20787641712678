import { AppListPlaceholder,NFTItem } from "components";
import { AppPrimaryOutlineButton } from "elements";
import { Card } from "semantic-ui-react";
import styled from "styled-components";

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const NFTList = ({ data,returnNFT,metadata }) => (
    <Card.Group itemsPerRow={4} stackable doubling>
        {data.map((item) => (
            <NFTItem
                key={item._id}
                item={item}
                metadata={item.nft_metadata}
                actionButton={<AppPrimaryOutlineButton fluid disabled={!item.canReturnIn} onClick={() => returnNFT(item)}>
                    Return NFT
                </AppPrimaryOutlineButton>}
            />
        ))}
    </Card.Group>
);

export const MyRentList = ({ loading,data,returnNFT }) => (
    <>
        {loading ? (
            <AppListPlaceholder rows={1} itemPerRow={4} />
        ) : (
            <NFTList
                data={data || []}
                returnNFT={returnNFT}
            />
        )}
        {!loading && data.length === 0 &&
            <div className="my-4">
                <SmallText>You are not renting any NFTs</SmallText>
            </div>
        }
    </>
)