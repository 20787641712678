export const Payment_ABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "admin",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "uint8",
				"name": "pt",
				"type": "uint8"
			}
		],
		"name": "getPaymentToken",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint8",
				"name": "index",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "currencyAddress",
				"type": "address"
			}
		],
		"name": "setPaymentToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]