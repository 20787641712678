import React,{ useState,useMemo,useEffect } from "react";
import styled from "styled-components";
import { Container,Confirm,Header,Icon } from "semantic-ui-react";
import { MyLendListHook,MyRentListHook } from "../hooks";
import { ContainerFull,CornerLogos } from "elements";
import { useStore } from "context/GlobalState";
import { MyLendList,MyRentList } from "page-components/wallet";
import { ErrorBox,LoaderBar } from "components";
import { claimColletralAsync,isApprovedForAll,returnItAsync,stopLendingAsync } from "store/asyncActions";
import { ContractUtility,ErrorConstant,LendService,RentService } from "utility";
import { ERC1155_ABI } from "contract/ERC1155_ABI";
import { ERC721_ABI } from "contract/ERC721_ABI";
import { RENTAL_ABI } from "contract/RENTAL";


const FilterBar = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const HeaderTitle = styled.span`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px;
`;

const ErrorViewMore = styled.span`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;
`

export const DashboardScreen = () => {

    let [{ web3,accounts,protocol }] = useStore();
    const contractAddress = useMemo(() => ContractUtility.getContractAddress(protocol),[protocol]);

    const { data: lends,loading: lendsLoading,setData: setLendData } = MyLendListHook(accounts ? accounts[0] : '',contractAddress);
    const { data: rents,loading: rentLoading,setData: setRentData } = MyRentListHook(accounts ? accounts[0] : '',contractAddress);

    const [lendingConfirm,setLendingConfirm] = useState(false);
    const [lendingAction,setLendingAction] = useState('');
    const [currentLending,setCurrentLending] = useState(null);

    const [rentConfirm,setRentConfirm] = useState(false);
    const [currentRent,setCurrentRent] = useState(null);

    const [processing,setProcessing] = useState('');
    const [error,setError] = useState('');
    const [transactionHash,setTransactionHash] = useState("");

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        if (processing) {
            window.addEventListener("beforeunload",unloadCallback);
            return () => window.removeEventListener("beforeunload",unloadCallback);
        }
    },[processing]);

    const stopLendingConfirm = (item) => {
        setLendingAction("stop");
        setCurrentLending(item);
        setLendingConfirm(true);
    }

    const claimCollateralConfirm = (item) => {
        setLendingAction("claim");
        setCurrentLending(item);
        setLendingConfirm(true);
    }

    const stopLending = async () => {
        try {
            setLendingConfirm(false);
            setProcessing('Cancelling Lend');
            const token_id = currentLending.token_id;
            const token_address = currentLending.token_address;
            const lend_id = currentLending.lend_id;
            const contract = new web3.eth.Contract(RENTAL_ABI,ContractUtility.getContractAddress(protocol));

            const receipt = await stopLendingAsync(contract,accounts,token_address,token_id,lend_id)
            const transaction_hash = receipt?.transactionHash;
            setTransactionHash(transaction_hash);

            if (receipt && receipt.status) {
                await LendService.cancel(currentLending._id);
                setLendData(lends.filter(x => x._id !== currentLending._id));
            } else {
                setError(ErrorConstant.default);
            }
        }
        catch (error) {
            console.log("error",error);
            const transaction_hash = error?.transactionHash;
            setTransactionHash(transaction_hash);
            setError(ErrorConstant.default);
        } finally {
            setProcessing('');
        }
    }

    const claimCollateral = async () => {
        try {
            setLendingConfirm(false);
            setProcessing('Claiming NFT');
            const token_id = currentLending.token_id;
            const token_address = currentLending.token_address;
            const lend_id = currentLending.lend_id;
            const contract = new web3.eth.Contract(RENTAL_ABI,ContractUtility.getContractAddress(protocol));
            const receipt = await claimColletralAsync(contract,accounts,token_address,token_id,lend_id)
            const transaction_hash = receipt?.transactionHash;
            setTransactionHash(transaction_hash);

            if (receipt && receipt.status) {
                await LendService.claim(currentLending._id);

                const newData = lends.map(x => {
                    if (x._id !== currentLending._id) {
                        return {
                            ...x,
                            claimCollateral: false
                        }
                    }
                    return x;
                });
                setLendData(newData);
            } else {
                setError(ErrorConstant.default);
            }
        }
        catch (error) {
            console.log("error",error);
            const transaction_hash = error?.transactionHash;
            setTransactionHash(transaction_hash);
            setError(ErrorConstant.default);
        } finally {
            setProcessing('');
        }
    }

    const lendingConfirmCancel = () => {
        setLendingAction("");
        setRentConfirm(null);
        setRentConfirm(false);
    }

    const lendingConfirmOk = () => {
        if (lendingAction === "stop") {
            stopLending();
        } else {
            claimCollateral();
        }
    }

    const returnNFTCancel = () => {
        setCurrentRent(null);
        setRentConfirm(false);
    }

    const returnNFTConfirm = (item) => {
        setCurrentRent(item);
        setRentConfirm(true);
    }

    const returnNFT = async () => {
        try {
            setRentConfirm(false);
            setProcessing('Returning NFT');
            const token_id = currentRent.token_id;
            const token_address = currentRent.token_address;
            const lend_id = currentRent.lend_id
            const contract_address = ContractUtility.getContractAddress(protocol);

            if (currentRent.nft_metadata.token_standard === "ERC1155") {
                const contractErc1155 = new web3.eth.Contract(
                    ERC1155_ABI,
                    token_address
                );
                const isApproved = await isApprovedForAll(contractErc1155,accounts[0],contract_address);
                if (!isApproved) {
                    await contractErc1155.methods
                        .setApprovalForAll(contract_address,true)
                        .send({ from: accounts[0] });
                }
            } else {
                const contractErc721 = new web3.eth.Contract(ERC721_ABI,token_address);
                const isApproved = await isApprovedForAll(contractErc721,accounts[0],contract_address);
                if (!isApproved) {
                    await contractErc721.methods
                        .approve(contract_address,token_id)
                        .send({ from: accounts[0] });
                }
            }
            const contract = new web3.eth.Contract(RENTAL_ABI,ContractUtility.getContractAddress(protocol));

            const receipt = await returnItAsync(contract,accounts,token_address,token_id,lend_id)
            const transaction_hash = receipt?.transactionHash;
            setTransactionHash(transaction_hash);

            if (receipt && receipt.status) {
                await RentService.return(currentRent._id);
                setRentData(rents.filter(x => x._id !== currentRent._id));
            } else {
                setError(ErrorConstant.default);
            }
        }
        catch (error) {
            console.log("error",error);
            const transaction_hash = error?.transactionHash;
            setTransactionHash(transaction_hash);
            setError(ErrorConstant.default);
        } finally {
            setProcessing('');
        }
    }

    const openTransaction = (tx) => {
        const url = ContractUtility.getTransaction(protocol,tx);
        window.open(url,"_blank");
    }

    return (
        <ContainerFull>
            <CornerLogos />
            <Container className="wrapper">
                {processing &&
                    <LoaderBar size="big" content={<>
                        <p className="m-0">{processing}</p>
                        <p>Don't close or refresh the page please. Transaction can take a while.</p>
                    </>}
                    />}
                <div>
                    <Header>
                        <HeaderTitle>Dashboard</HeaderTitle>
                    </Header>
                </div>
                <FilterBar>
                    <SmallText>Lend Dashboard</SmallText>
                </FilterBar>

                <div>
                    <MyLendList
                        data={lends}
                        loading={lendsLoading}
                        stopLending={stopLendingConfirm}
                        claimCollateral={claimCollateralConfirm}
                    />
                </div>

                <FilterBar className="my-4">
                    <SmallText>Rent Dashboard</SmallText>
                </FilterBar>
                <div>
                    <MyRentList
                        data={rents}
                        loading={rentLoading}
                        returnNFT={returnNFTConfirm}
                    />
                </div>
            </Container>

            <Confirm
                size="mini"
                open={lendingConfirm}
                onCancel={lendingConfirmCancel}
                onConfirm={lendingConfirmOk}
            />
            <Confirm
                size="mini"
                open={rentConfirm}
                onCancel={returnNFTCancel}
                onConfirm={returnNFT}
            />
            <ErrorBox
                open={!!error}
                closeModal={() => setError("")}
                title={<>{error} {transactionHash && <ErrorViewMore
                    className="ml-1"
                    onClick={() => openTransaction(transactionHash)}
                >
                    View Logs <Icon name="external alternate" />
                </ErrorViewMore>}
                </>}
            />
        </ContainerFull>
    );
};
