import { Container,Grid } from "semantic-ui-react";
import { AppListPlaceholder,NFTItem,PaymentTypeImage } from "../components";
import { ContainerFull,CornerLogos,AppPrimaryOutlineButton,PageTitle } from "elements";
import React,{ useMemo } from "react";
import styled from 'styled-components';
import { RentListHook } from "../hooks";
import { useNavigate } from "react-router";
import { useStore } from "context/GlobalState";
import { ContractUtility,PaymentType } from "utility";

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const ExtraInfo = styled(Grid)`
  margin : 1rem 0 !important;

  .row{
    padding : 0.5rem 0 !important;
    margin : 0 !important;
    border-bottom : 1px solid #e2e2e2;
    div{
      overflow : hidden;
      text-overflow: ellipsis;
    }
    .ten {
      padding-left : 0 !important;
    }
    .six {
      padding-right : 0 !important;
    }
  }
`

const Address = styled(Grid.Column)`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;
`

const NFTList = ({ data,connected,onClick,tokenAddress }) => (
  <Grid>
    {data.map(item => (
      <Grid.Column mobile={16} tablet={8} computer={4} key={item._id}>
        <NFTItem
          item={item}

          metadata={item.nft_metadata}
          onClick={() => onClick(item)}
          extraInfo={<ExtraInfo >
            <Grid.Row>
              <Grid.Column width={10}>Token Id</Grid.Column>
              <Grid.Column width={6}>{item.token_id}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>Token Address</Grid.Column>
              <Address width={6} onClick={() => tokenAddress(item.token_address)} title={item.token_address}>
                {item.token_address}
              </Address>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>Max Duration(Days)</Grid.Column>
              <Grid.Column width={6}>{item.max_duration}</Grid.Column>
            </Grid.Row>
            <Grid.Row >
              <Grid.Column width={10}>Daily Rent Price</Grid.Column>
              <Grid.Column width={6}><PaymentTypeImage type={item.payment_type || PaymentType.WETH} className="mx-1" />{item.daily}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>Colleteral</Grid.Column>
              <Grid.Column width={6}><PaymentTypeImage type={item.payment_type || PaymentType.WETH} className="mx-1" /> {item.nft}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>Standard</Grid.Column>
              <Grid.Column width={6}>{item.nft_metadata?.token_standard}</Grid.Column>
            </Grid.Row>
          </ExtraInfo>}
          actionButton={<AppPrimaryOutlineButton disabled={!connected} fluid onClick={() => onClick(item)}>
            Rent Now (<PaymentTypeImage type={item.payment_type || PaymentType.WETH} className="mx-1" />{item.nft} )
          </AppPrimaryOutlineButton>}
        />
      </Grid.Column>
    ))}
  </Grid>
);

export const RentScreen = () => {

  let [{ accounts,network,networkShouldBe,web3Loadded,protocol }] = useStore();

  const allowTransaction = useMemo(() => web3Loadded && network.toLowerCase() === networkShouldBe.toLowerCase(),[network,networkShouldBe,web3Loadded])
  const contractAddress = useMemo(() => ContractUtility.getContractAddress(protocol),[protocol]);
  let navigate = useNavigate();

  const { data,loading } = RentListHook(accounts ? accounts[0] : '',contractAddress);

  const onClick = (item) => {
    navigate(`/rent-details/${item._id}`);
  };

  const tokenAddress = (address) => {
    const url = ContractUtility.getTokenAddress(protocol,address);
    window.open(url,"_blank");
  }

  return (
    <ContainerFull>
      <CornerLogos />
      <Container className="wrapper">
        <PageTitle>Rent a NFT now!</PageTitle>
        <div>
          {loading ? <AppListPlaceholder rows={2} itemPerRow={4} /> : <NFTList
            data={data || []}
            onClick={onClick}
            connected={allowTransaction && web3Loadded}
            tokenAddress={tokenAddress}
          />}
          {!loading && data.length === 0 &&
            <div className="my-4">
              <SmallText>No NFT for rent</SmallText>
            </div>
          }
        </div>
      </Container>
    </ContainerFull>
  );
};
