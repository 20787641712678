
export class CommonUtility {

	static currencyFormat = (value,currency) =>
		isNaN(value || 0) ? value : new Intl.NumberFormat("en-US",{
			style: "currency",
			currency: currency || "USD",
		}).format(value || 0);

	static isNotEmpty = (item) =>
		item !== undefined && item !== null && item !== '' && item.length !== 0;

	static truncateString = (text,ellipsisString) =>
		(text || "").length > ellipsisString
			? text.substring(0,ellipsisString) + "..."
			: text;

	static numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");

	static objectToParams = (obj) => {
		let str = "";
		for (const key in obj) {
			if (obj[key] !== undefined && obj[key] !== null) {
				if (str !== "") {
					str += "&";
				}
				str += key + "=" + encodeURIComponent(obj[key]);
			}
		}
		return str;
	};

	static toTitleCase = (phrase) => phrase
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');


	static timeoutPromise = (ms) => {
		return new Promise(resolve => setTimeout(resolve,ms));
	}

	static roundNumber = (num,decimals) => {
		const t = Math.pow(10,decimals);
		return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100,decimals)))) / t).toFixed(decimals);
	};

};