import React from "react";
import styled from "styled-components";
import { Card,Dropdown,Input } from "semantic-ui-react";
import { HomeRentHook } from "../hooks";
import { AppListPlaceholder,NFTItem } from "../components";
import { ContainerFull,CornerLogos,PageTitle,PageSubTitle } from "elements";
import { Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useStore } from "context/GlobalState";

const FilterBar = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SmallText = styled.span`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: bold;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.374px;
`;

const TendNow = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: right;
  .textWrapper {
    width: 320px;
    >.title {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 35px;
      color: #6d6d6d;
    }
    >.description {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.374px;
      color: rgba(134, 134, 134, 0.8);
    }
  }

  .addWrapper {
    display: flex;
    align-items: center;
    padding-left: 26px;
    .add {
      cursor: pointer;
      background: linear-gradient(
        135deg,
        rgba(158, 158, 158, 0.15) 0%,
        rgba(0, 0, 0, 0.15) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 20px;
      padding: 0px 20px;
      > .plus {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        color: #7c7c7c;
      }
    }
  }
`;

const NFTList = ({ data,imgClick }) => (
  <Card.Group itemsPerRow={4} stackable doubling>
    {data.map((item) => (
      <NFTItem
        key={item._id}
        item={item}
        metadata={item.nft_metadata}
        imgClick={() => imgClick(item)}
      />
    ))}
  </Card.Group>
);

export const HomeScreen = () => {
  let [{ accounts }] = useStore();
  let navigate = useNavigate();

  const { data,loading } = HomeRentHook(accounts ? accounts[0] : '');

  const imgClick = (item) => {
    navigate(`/rent-details/${item.id}`)
  }

  return (
    <ContainerFull>
      <CornerLogos />
      <Container className="wrapper">
        <PageTitle>Make NFTS</PageTitle>
        <PageSubTitle>work for you.</PageSubTitle>
        <FilterBar>
          <div>
            <SmallText>Featured new listings</SmallText>
          </div>
          <div>
            <Input icon="search" placeholder="Search..." />
            <Dropdown
              placeholder="Sort by"
              selection
              options={[]}
              className="mx-3"
            />
            <Dropdown placeholder="Filter by" selection options={[]} />
          </div>
        </FilterBar>

        <div>
          {loading ? <AppListPlaceholder rows={1} itemPerRow={4} /> : <NFTList
            data={data || []}
            imgClick={imgClick}
          />}
          {!loading && data.length === 0 &&
            <div className="my-4">
              <SmallText>No NFT for rent</SmallText>
            </div>
          }
        </div>

        <TendNow>
          <div className="textWrapper">
            <h1 className="title">Lend your NFTs now</h1>
            <p className="description">
              Feugiat a ultricies blandit sed pharetra. Rutrum quis tempor
              dictumst ac lacinia.
            </p>
          </div>
          <Link to="/lend">
            <div className="addWrapper">
              <div className="add">
                <p className="plus">+</p>
              </div>
            </div>
          </Link>
        </TendNow>
      </Container>
    </ContainerFull>
  );
};
