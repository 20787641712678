import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const AppPrimaryButton = styled(Button)`
    background-color : ${({ theme }) => theme.colors.primary} !important;
    border-color : ${({ theme }) => theme.colors.primary} !important;
    color : white !important;
    border-radius: 20px !important;
`

export const AppPrimaryCancelButton = styled(Button)`
    background-color : ${({ theme }) => theme.closeButton.background} !important;
    color : white !important;
    border-radius: 20px !important;
`

export const AppPrimaryOutlineButton = styled(Button)`
    background : ${({ theme }) => theme.button.outline} !important;
    border: 2px solid !important;
    border-color : ${({ theme }) => theme.button.border} !important;
    color : ${({ theme }) => theme.button.border} !important;
    border-radius: 20px !important;
`

export const AppBorderPrimaryButton = styled(AppPrimaryButton)`
    border-radius: 50px !important;
`

export const AppPrimaryHoverButton = styled(Button)`
    color : ${({ theme }) => theme.colors.primary} !important;
    border-color : ${({ theme }) => theme.colors.primary} !important;
    background-color : white !important;

    &:hover{
        background-color : ${({ theme }) => theme.colors.primary} !important;
        color : white !important;
    }
`

export const AppDangerButton = styled(Button)`
    background-color : ${({ theme }) => theme.colors.danger} !important;
    border-color : ${({ theme }) => theme.colors.danger} !important;
    color : white !important;
`

export const AppDangerHoverButton = styled(Button)`
    color : ${({ theme }) => theme.colors.danger} !important;
    border-color : ${({ theme }) => theme.colors.danger} !important;
    background-color : white !important;

    &:hover{
        background-color : ${({ theme }) => theme.colors.danger} !important;
        color : white !important;
    }
`