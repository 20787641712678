export const Images = {
    metamask: require("./metamask.svg")?.default,
    opensea: require("./opensea.svg")?.default,
    rarible: require("./rarible.png")?.default,
    card1: require("./card-1.png")?.default,
    card2: require("./card-2.png")?.default,
    card3: require("./card-3.png")?.default,
    card4: require("./card-4.png")?.default,
    logo: require('./logo.png')?.default,
    logoTopRight: require('./top-right-logo.png')?.default,
    logoBottomLeft: require('./bottom-left-logo.png')?.default,
    successTickRounded: require('./success-tick-rounded.png')?.default,
    error: require('./error.png')?.default,
    networks: {
        ethereum: require('./networks/ethereum.jpeg')?.default,
        bsc: require('./networks/bsc.png')?.default,
        polygon: require('./networks/polygon.svg')?.default,
    },
    payment: {
        weth: require('./payment/weth.svg')?.default,
        usdc: require('./payment/usdc.svg')?.default,
    },
    info: require('./info.svg')?.default
}