import { useEffect,useState } from "react"
import { ErrorConstant,RentService } from "../utility";
import { unpackDailyPrice } from "store/bytesHandler";

export const HomeRentHook = (address) => {

    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await RentService.get({
                    user: address
                });
                const temp = (result.data || []).splice(0,4)
                    .map(item => ({
                        ...item,
                        nft: unpackDailyPrice(item.nft_price),
                        daily: unpackDailyPrice(item.daily_rent_price),
                    }));
                setData(temp);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if (address) {
            fetchData();
        }
    },[address]);

    return { data,loading,error };
}

export const RentListHook = (address,contractAddress) => {


    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await RentService.get({
                    user: address,
                    contract_address: contractAddress
                });
                const temp = (result.data || [])
                    .map(item => ({
                        ...item,
                        nft: unpackDailyPrice(item.nft_price),
                        daily: unpackDailyPrice(item.daily_rent_price),
                    }));
                setData(temp);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    },[address,contractAddress]);

    return { data,loading,error };
}


export const RentDetailsHook = (id) => {

    const [data,setData] = useState(null);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await RentService.getById(id);
                const item = result.data;
                if (item) {
                    setData({
                        ...item,
                        nft: unpackDailyPrice(item.nft_price),
                        daily: unpackDailyPrice(item.daily_rent_price),
                    });
                }
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }
        if (id) {
            fetchData();
        }
    },[id]);

    return { data,loading,error };
}

export const MyRentListHook = (address,contractAddress) => {

    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let result = await RentService.my(address,contractAddress);
                const temp = (result.data || []).map(item => {
                    let endPoint = (item.block_timestamp || 0) + (item.duration_seconds || 0);
                    let startPoint = ~~(Date.now() / 1000);
                    return {
                        ...item,
                        nft: unpackDailyPrice(item.nft_price),
                        daily: unpackDailyPrice(item.daily_rent_price),
                        canReturnIn: startPoint <= endPoint
                    }
                });
                setData(temp);
            } catch (error) {
                console.log(error);
                setError(ErrorConstant.default)
            } finally {
                setLoading(false);
            }
        }

        if (address && contractAddress) {
            fetchData();
        }
    },[address,contractAddress]);

    return { data,loading,error,setData };
}
