import React,{ useEffect,useMemo,useState } from "react";
import styled from "styled-components";
import {
  Checkbox,
  Form,
  Grid,
  Segment,
  Container,
  List,
  Icon,
} from "semantic-ui-react";
import { UserNFTDetailsHook } from "../hooks";
import {
  LoaderBar,
  SuccessBox,
  ErrorBox,
  ImageWithFallback,
  VeraCardShadow
} from "../components";
import { useNavigate,useParams } from "react-router";
import {
  AppPrimaryButton,FormTextFormField,ContainerFull,
  Box,PageTitle,IntputWrapper,SubmitWrapper,DropdownFormField
} from "elements";
import { lendAsync,getLendIdAsync,isApprovedForAll } from "store/asyncActions";
import { ContractUtility,LendService,PaymentMethods } from "utility";
import { useStore } from "context/GlobalState";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Images } from "images";
import { ERC721_ABI } from "contract/ERC721_ABI";
import { ERC1155_ABI } from "contract/ERC1155_ABI";
import { packPrice } from "store/bytesHandler";
import { RENTAL_ABI } from "contract/RENTAL";

const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
`;

const Details = styled(Segment)`
  &.ui.segment {
    background: #fcfcfd !important;
    box-shadow: none !important;
    border-radius: 20px !important;
    border: none !important;
    .item:first-child {
      padding-top: 0px;
    }
    .item {
      border-top: 0px;
      color: #777e90;
      padding-top: 10px;
    }
    .right {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70px;
    }
  }
`;

const Address = styled(List.Content)`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;

`

const ErrorViewMore = styled.span`
  color : ${({ theme }) => theme.colors.primary};
  cursor :pointer;
`

const DetailsSchema = yup.object().shape({
  duration: yup.number().required("Duration is required").positive("Duration should be positive"),
  colletral: yup.number().required().positive("Colletral should be positive"),
  dailyPrice: yup.number().required().positive("Daily Price should be positive"),
  type: yup.number().required()
});

export const LendDetailsScreen = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [{ web3,accounts,protocol }] = useStore();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(DetailsSchema),
    defaultValues: {
      type: PaymentMethods[0].value
    }
  });

  const { data,loading } = UserNFTDetailsHook(protocol,accounts ? accounts[0] : "",params?.tokenId,params?.tokenAddress);
  const [formData,setFormData] = useState({
    terms: false,
    ownRisk: false,
  });

  const watchField = watch("type");
  const paymentMethod = useMemo(() => PaymentMethods.find(x => x.value === watchField)?.text,[watchField])

  const [processing,setProcessing] = useState("");
  const [error,setError] = useState("");
  const [success,setSuccess] = useState(false);
  const [transactionHash,setTransactionHash] = useState("");

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    if (processing) {
      window.addEventListener("beforeunload",unloadCallback);
      return () => window.removeEventListener("beforeunload",unloadCallback);
    }
  },[processing]);

  const inputChanged = (key,value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const approve = async (inputData) => {
    try {
      setSuccess(false);
      setError("");
      setProcessing("Approving");

      const token_address = data.asset_contract.address;
      const token_id = data.token_id;
      const contract_address = ContractUtility.getContractAddress(protocol);

      if (data.asset_contract.schema_name === "ERC1155") {
        const contractErc1155 = new web3.eth.Contract(
          ERC1155_ABI,
          token_address
        );

        const isApproved = await isApprovedForAll(contractErc1155,accounts[0],contract_address);
        if (!isApproved) {
          await contractErc1155.methods
            .setApprovalForAll(contract_address,true)
            .send({ from: accounts[0] });
        }
      } else {
        const contractErc721 = new web3.eth.Contract(ERC721_ABI,token_address);

        const isApproved = await isApprovedForAll(contractErc721,accounts[0],contract_address);
        if (!isApproved) {
          await contractErc721.methods
            .approve(contract_address,token_id)
            .send({ from: accounts[0] });
        }
      }

      const contract = new web3.eth.Contract(RENTAL_ABI,ContractUtility.getContractAddress(protocol));


      const daily_rent_price = packPrice(inputData.dailyPrice);
      const nft_price = packPrice(inputData.colletral);
      const lendId = await getLendIdAsync(contract);

      const receipt = await lendAsync(
        contract,
        accounts,
        token_address,
        token_id,
        inputData.duration,
        daily_rent_price,
        nft_price,
      );

      const transaction_hash = receipt?.transactionHash;
      setTransactionHash(transaction_hash);

      if (receipt && receipt.status) {
        const reqData = {
          owner_address: accounts[0],
          lend_id: lendId,
          token_id,
          token_address,
          max_duration: inputData.duration,
          duration: inputData.duration,
          payment_type: inputData.type,
          nft_price,
          daily_rent_price,
          contract_address,
          transaction_hash,
          nft: {
            description: data?.description,
            name: data?.name,
            image: data?.image_preview_url,
            token_id,
            token_address,
            token_uri: data?.token_metadata,
            token_standard: data.asset_contract?.schema_name,
            permalink: data?.permalink
          }
        }
        await LendService.add(reqData);
        setSuccess(true);
      } else {
        setError("Error while processing");
      }
    } catch (error) {
      console.log("error",error);
      const transaction_hash = error?.transactionHash;
      setTransactionHash(transaction_hash);
      setError("Error while processing");
    } finally {
      setProcessing("");
    }
  };

  const goToDashboard = () => {
    setSuccess(false)
    navigate("/dashboard");
  }

  const tokenAddress = (address) => {
    const url = ContractUtility.getTokenAddress(protocol,address);
    window.open(url,"_blank");
  }

  const openTransaction = (tx) => {
    const url = ContractUtility.getTransaction(protocol,tx);
    window.open(url,"_blank");
  }

  return (
    <ContainerFull>
      <Container>
        <PageTitle>Lend your NFT</PageTitle>
        <Box>
          <div>
            {(loading || processing) && (
              <LoaderBar size="big" content={processing ? <>
                <p className="m-0">{processing}</p>
                <p>Don't close or refresh the page please. Transaction can take a while.</p>
              </> : "Loading"} />
            )}
            {!loading && data && (
              <Grid>
                <Grid.Column width={10}>
                  <Form noValidate onSubmit={handleSubmit(approve)}>
                    <div>
                      <FormTextFormField
                        control={control}
                        label="Duration (in Days)"
                        width={8}
                        placeholder="Enter duration in days"
                        defaultValue={""}
                        type="number"
                        name="duration"
                        required
                        errors={errors.duration}
                      />
                    </div>
                    <div>
                      <DropdownFormField
                        control={control}
                        options={PaymentMethods}
                        label="Payment Type"
                        width={8}
                        placeholder="Payment Type"
                        defaultValue={""}
                        name="type"
                        required
                        errors={errors.type}
                      />
                    </div>
                    <div>
                      <FormTextFormField
                        control={control}
                        label={`Collateral (in ${paymentMethod})`}
                        width={8}
                        placeholder={`Enter colletral in ${paymentMethod}`}
                        defaultValue={""}
                        type="number"
                        name="colletral"
                        required
                        errors={errors.colletral}
                      />
                    </div>
                    <div>
                      <FormTextFormField
                        control={control}
                        label={`Daily Price (in ${paymentMethod})`}
                        width={8}
                        placeholder={`Enter daily price in ${paymentMethod}`}
                        defaultValue={""}
                        type="number"
                        name="dailyPrice"
                        required
                        errors={errors.dailyPrice}
                      />
                    </div>

                    <IntputWrapper>
                      <div className="vera-input">
                        <div>
                          <Checkbox
                            label="I agree with the Terms & Services "
                            checked={formData.terms}
                            onChange={(_,data) =>
                              inputChanged("terms",data.checked)
                            }
                          />
                        </div>

                        <div>
                          <Checkbox
                            label="Contract in beta and not audited, I am using at my own risk"
                            checked={formData.ownRisk}
                            onChange={(_,data) =>
                              inputChanged("ownRisk",data.checked)
                            }
                          />
                        </div>
                      </div>
                    </IntputWrapper>

                    <SubmitWrapper>
                      <AppPrimaryButton
                        type="submit"

                        disabled={!formData.terms || !formData.ownRisk}
                      >
                        Approve
                        {/* {data?.asset_contract?.schema_name === "ERC1155" ? "Coming Soon" : "Approve"} */}
                      </AppPrimaryButton>
                    </SubmitWrapper>
                  </Form>
                </Grid.Column>
                <Grid.Column width={6}>
                  <VeraCardShadow>
                    <ImageWithFallback
                      fallback={Images.card1}
                      src={data?.image_preview_url || Images.card1}
                      size="medium"
                    />
                    <Title>{data?.name}</Title>

                    <Details>
                      <List divided verticalAlign="middle">
                        <List.Item>
                          <Address floated="right" onClick={() => tokenAddress(data.asset_contract?.address)} title={data.asset_contract?.address}>
                            <p>{data.asset_contract?.address}</p>
                          </Address>
                          <List.Content>Address</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated="right">
                            <p>{data?.token_id}</p>
                          </List.Content>
                          <List.Content>Token ID</List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content floated="right">
                            <p>{data.asset_contract?.schema_name}</p>
                          </List.Content>
                          <List.Content>Token Standard</List.Content>
                        </List.Item>
                      </List>
                    </Details>
                  </VeraCardShadow>
                </Grid.Column>
              </Grid>
            )}
          </div>
          <SuccessBox
            open={success}
            closeModal={() => setSuccess(false)}
            title={<p>The NFT is now working for you!</p>}
            actionBar={
              <AppPrimaryButton onClick={() => goToDashboard()}>Go To Dashboard</AppPrimaryButton>
            }
          />
          <ErrorBox
            open={!!error}
            closeModal={() => setError("")}
            title={<>{error} {transactionHash && <ErrorViewMore
              className="ml-1"
              onClick={() => openTransaction(transactionHash)}
            >
              View Logs <Icon name="external alternate" />
            </ErrorViewMore>}
            </>}
          />
        </Box>
      </Container>
    </ContainerFull>
  );
};
