import { useMemo } from "react";
import { Placeholder,Card } from "semantic-ui-react";
import { VeraPlaceholderCard } from './../elements/Common'
import { Grid } from 'semantic-ui-react'

export const AppListPlaceholder = ({ rows = 3,itemPerRow = 3 }) => {
    const lines = useMemo(() => Array.from({ length: rows },(_,k) => k),[rows]);
    const columns = useMemo(() => Array.from({ length: itemPerRow },(_,k) => k),[itemPerRow]);

    return (
        <Grid>
            {
                lines.map(line => (
                    <Grid.Row key={line}>
                        {columns.map((column) => (
                            <Grid.Column mobile={16} tablet={8} computer={16 / itemPerRow} key={column}>
                                <VeraPlaceholderCard key={column}>
                                    <Placeholder>
                                        <Placeholder.Image className="image" square />
                                    </Placeholder>
                                    <Card.Content>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line className="title" length='very short' />
                                                <Placeholder.Line className="subTitle" length='medium' />
                                            </Placeholder.Header>
                                        </Placeholder>
                                    </Card.Content>
                                </VeraPlaceholderCard>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                ))
            }
        </Grid>

        // <Grid container columns={3} divided>
        //     <Grid.Row>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //     </Grid.Row>

        //     <Grid.Row>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //         <Grid.Column mobile={16} tablet={8} computer={4}>
        //             <Image src='https://react.semantic-ui.com/images/wireframe/media-paragraph.png' />
        //         </Grid.Column>
        //     </Grid.Row>
        // </Grid>
    );
}